<template>
  <div class="slideshow-banner">
    <img class="for_mobile" v-bind:src="'https://api.bisnisonlinebgs.com/video/asset-bisnisonlinebgs/banner_potrait_' + bannerImg" width="100%" alt="Bisnis Online" desc="Bisnis Online Tanpa Modal">
    <img class="for_tablet" v-bind:src="'https://api.bisnisonlinebgs.com/video/asset-bisnisonlinebgs/header_' + bannerImg" width="100%" alt="Bisnis Online" desc="Bisnis Online Tanpa Modal">
     <img class="for_desktop" v-bind:src="'https://api.bisnisonlinebgs.com/video/asset-bisnisonlinebgs/header_' + bannerImg" width="100%" alt="Bisnis Online" desc="Bisnis Online Tanpa Modal">
    <!-- <b-container fluid>
        <b-row>
            <img class="for_desktop" src="https://api.bisnisonlinebgs.com/video/asset-bisnisonlinebgs/header.jpeg" width="100%" alt="Bisnis Online" desc="Bisnis Online Tanpa Modal">
        </b-row>
    </b-container> -->
  </div>
</template>

<script>
  export default {
    data() {
      return {
        bannerImg:null
      }
    },
    mounted() {
      // localStorage.setItem('bahasa', 'id')
      // if(localStorage.getItem('bahasa') == null){
      //   localStorage.setItem('bahasa', 'id')
      // }

      var bahasa =  localStorage.getItem('bahasa')

      this.bannerImg = `${bahasa}.jpeg`

    },
    computed: {

    },
    methods: {

    }
  }
</script>

